export const environment = {
  production: false,
  apiUrl: 'http://localhost:7066/api',
  appUrl: 'http://localhost:4200',
  pcxCardUrl: 'http://localhost:4200/auth/verify-user?from=pcx&id=',
  peachPayments: {
    entityId: '8ac7a4c8920e912101920f93ad4f030f',
    secretKey: '07ad612a72a04345a5788af207cf1142',
    url: 'https://testsecure.peachpayments.com'
  },
  googleMapsApiKey: 'AIzaSyCUzFF-oTJfCV2kTYMXsPQZw5MQhq5nfl0',
  whatsappNumber: '27684303308',
};
